export const allProductsEN = [

    {
        id: 1,
        name: "STANDARD",
        price: "199",
        description: <>Basic package for amateurs</>,
        promo_price: 170,
        bestseller: false,
        type: 'normal',
        users_number: 1,
        support_days: 30,
        service_name: 'STANDARD',
        duration: 60,
        status: 'active',
        benefits_list: [
            {
                main_text: 'PRO SERWIS',
                popover: {
                    title: 'PRO SERWIS',
                    text: <><strong>Increases FPS, minimizes hardware delays, eliminates image stuttering, and reduces PING.</strong><br /><br />PRO SERWIS is a full optimization of the Windows system, significantly increasing the performance of games, programs, and the entire system. It includes optimization of settings: system, graphics, internet, power, games, audio, keyboard, mouse, and software, as well as updates for all drivers.</>
                }
            },
            {
                main_text: 'Basic BIOS settings',
                popover: {
                    title: 'BIOS',
                    text: <><strong>BIOS (Basic Input/Output System)</strong> - built-in software located on the computer's motherboard. BIOS plays a significant role in starting computer components, diagnostics and error handling, and enables advanced configuration of components. <br /><br />Today, BIOS is often replaced by UEFI, a more modern and advanced system, but colloquially, we still refer to this software as BIOS.</>
                }
            },
            {
                main_text: '30 days of technical support',
                popover: {
                    title: 'Technical support',
                    text: <>In case of questions or technical problems after the service, our specialist will be available for the specified period. <br /><br /><strong>We will gladly help and advise</strong>, and if necessary, we will connect with you again!</>
                }
            },
        ]

    },

    {
        id: 2,
        name: "PRO",
        price: "370",
        description: <>Comprehensive set for professionals</>,
        promo_price: 285,
        bestseller: true,
        type: 'normal',
        users_number: 1,
        support_days: 60,
        service_name: 'PRO',
        duration: 180,
        status: 'active',
        benefits_list: [
            {
                main_text: 'PRO SERWIS',
                popover: {
                    title: 'PRO SERWIS',
                    text: <><strong>Increases FPS, minimizes hardware delays, eliminates image stuttering, and reduces PING.</strong><br /><br />PRO SERWIS is a full optimization of the Windows system, significantly increasing the performance of games, programs, and the entire system. It includes optimization of settings: system, graphics, internet, power, games, audio, keyboard, mouse, and software.</>
                }
            },
            {
                main_text: 'Advanced BIOS settings',
                popover: {
                    title: 'BIOS',
                    text: <><strong>BIOS (Basic Input/Output System)</strong> - built-in software located on the computer's motherboard. BIOS plays a significant role in starting computer components, diagnostics and error handling, and enables advanced configuration of components. <br /><br />Today, BIOS is often replaced by UEFI, a more modern and advanced system, but colloquially, we still refer to this software as BIOS.</>
                }
            },
            {
                main_text: 'Overclocking',
                popover: {
                    title: 'Overclocking',
                    text: <><strong>Overclocking</strong> - increasing the performance of computer components, such as the graphics card, RAM, or processor, by raising clock frequencies and adjusting other settings. <br /><br />It's an advanced process that requires a lot of knowledge and patience. <strong>GainFPS specialists avoid and discourage excessive overclocking, while offering safe and reasonable overclocking of components</strong>, which, combined with free technical support, guarantees long-term <strong>performance and stability.</strong></>
                }
            },
            {
                main_text: 'RAM settings',
                popover: {
                    title: 'RAM operation',
                    text: <>RAM is an extremely important component of every computer. It works closely with the processor, storing and transmitting data needed for operations. <br /><br /><strong>Well-configured RAM works much faster</strong> than straight out of the box, resulting in <strong>less latency</strong> and <strong>higher FPS in games</strong>, as well as <strong>more efficient operation of programs</strong>.</>
                }
            },
            {
                main_text: 'Process optimization',
                popover: {
                    title: 'Operation of processes in the system',
                    text: <>Processes can be called occurrences of computer programs and all applications that you open and run on your computer. At one time, there can be hundreds of processes running on the system. The operating system manages them in a structured way, allocating appropriate resources. <br /><br /><strong>Effective task prioritization and accurate allocation of RAM and processor resources are crucial for achieving smooth visuals and maximum performance.</strong></>
                }
            },
            {
                main_text: '60 days of technical support',
                popover: {
                    title: 'Technical support',
                    text: <>In case of questions or technical problems after the service, our specialist will be available for the specified period. <br /><br /><strong>We will gladly help and advise</strong>, and if necessary, we will connect with you again!</>
                }
            },
        ]
    },

    {
        id: 3,
        name: "PREMIUM",
        price: "699",
        description: <>Specialized stationary service <strong>(available in Poland)</strong></>,
        promo_price: null,
        bestseller: false,
        type: 'normal',
        users_number: 1,
        button: 'contact',
        support_days: 90,
        service_name: 'PREMIUM',
        duration: 420,
        status: 'active',
        benefits_list: [
            {
                main_text: 'PRO SERWIS',
                popover: {
                    title: 'PRO SERWIS',
                    text: <><strong>Increases FPS, minimizes hardware delays, eliminates image stuttering, and reduces PING.</strong><br /><br />PRO SERWIS is a full optimization of the Windows system, significantly increasing the performance of games, programs, and the entire system. It includes optimization of settings: system, graphics, internet, power, games, audio, keyboard, mouse, and software.</>
                }
            },
            {
                main_text: 'Advanced BIOS settings',
                popover: {
                    title: 'BIOS',
                    text: <><strong>BIOS (Basic Input/Output System)</strong> - built-in software located on the computer's motherboard. BIOS plays a significant role in starting computer components, diagnostics and error handling, and enables advanced configuration of components. <br /><br />Today, BIOS is often replaced by UEFI, a more modern and advanced system, but colloquially, we still refer to this software as BIOS.</>
                }
            },
            {
                main_text: 'Precise overclocking',
                popover: {
                    title: 'Overclocking',
                    text: <><strong>Overclocking</strong> - increasing the performance of computer components, such as the graphics card, RAM, or processor, by raising clock frequencies and adjusting other settings. <br /><br />It's an advanced process that requires a lot of knowledge and patience. <strong>GainFPS specialists avoid and discourage excessive overclocking, while offering safe and reasonable "overclocking" of components</strong>, which, combined with free technical support, guarantees long-term <strong>performance and stability.</strong></>
                }
            },
            {
                main_text: 'Manual setting of all RAM timings',
                popover: {
                    title: 'RAM operation',
                    text: <>RAM is an extremely important component of every computer. It works closely with the processor, storing and transmitting data needed for operations.<br /><br /><strong>Well-configured RAM works much faster</strong> than straight out of the box, resulting in <strong>less latency</strong> and <strong>higher FPS in games</strong>, as well as <strong>more efficient operation of programs</strong>.</>
                }
            },
            {
                main_text: 'Advanced optimization of processes and driver operations',
                popover: {
                    title: 'Operation of processes in the system',
                    text: <>Processes can be called occurrences of computer programs and all applications that you open and run on your computer. At one time, there can be hundreds of processes running on the system. The operating system manages them in a structured way, allocating appropriate resources. <br /><br /><strong>Effective task prioritization and accurate allocation of RAM and processor resources are crucial for achieving smooth visuals and maximum performance.</strong></>
                }
            },
            {
                main_text: 'Cleaning the computer from the inside',
                popover: {
                    title: 'Cleaning and maintenance',
                    text: <>Cleaning the computer from the inside is important not only to maintain optimal performance but also for the long-term health and durability of the equipment. We use special tools for cleaning and maintaining equipment, allowing for thorough removal of dust from even the most difficult-to-reach places, such as radiators and fans.</>
                }
            },
            {
                main_text: 'Replacement of thermal pastes',
                popover: {
                    title: 'Thermal paste',
                    text: <>Thermal paste is a substance used between the surface of the processor (CPU) or graphics card (GPU) and the cooling system.<br /><br />The main purpose of thermal paste is to improve heat conduction and more efficient cooling of devices, resulting in longer component life and better performance.</>
                }
            },
            {
                main_text: 'Diagnostics and extended stress tests',
                popover: {
                    title: 'Diagnostics and tests',
                    text: <>We check your equipment for potential malfunctions and faulty operation of components.</>
                }
            },
            {
                main_text: 'Possibility of package personalization',
                popover: {
                    title: 'We will fulfill your request',
                    text: <>Are you starting a streaming career and want to set up OBS? Maybe you are upgrading components or have just bought a computer in separate parts and need help? <br /><br /><strong>We will be happy to help, just let us know!</strong></>
                }
            },
            {
                main_text: '90 days of technical support',
                popover: {
                    title: 'Technical support',
                    text: <>In case of questions or technical problems after the service, our specialist will be available for the specified period. <br /><br /><strong>We will gladly help and advise</strong>, and if necessary, we will connect with you again!</>
                }
            },
        ]
    },

];

export default allProductsEN;
